<template>
  <div>
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.125 3.25H4.875C4.44402 3.25 4.0307 3.4212 3.72595 3.72595C3.4212 4.0307 3.25 4.44402 3.25 4.875V21.125C3.25 21.556 3.4212 21.9693 3.72595 22.274C4.0307 22.5788 4.44402 22.75 4.875 22.75H21.125C21.556 22.75 21.9693 22.5788 22.274 22.274C22.5788 21.9693 22.75 21.556 22.75 21.125V4.875C22.75 4.44402 22.5788 4.0307 22.274 3.72595C21.9693 3.4212 21.556 3.25 21.125 3.25ZM4.875 21.125V6.02367L19.9763 21.125H4.875ZM6.02367 4.875H21.125V19.9773L6.02367 4.875Z"
        fill="#217BCD"
      />
    </svg>
  </div>
</template>

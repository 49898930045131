<template>
  <div>
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.996 12.5534 27.6251 9.24911 25.188 6.812C22.7509 4.37488 19.4466 3.00397 16 3ZM27 16C27.0009 17.0145 26.8608 18.0241 26.5838 19H21.77C22.0767 17.0118 22.0767 14.9882 21.77 13H26.5838C26.8608 13.9759 27.0009 14.9855 27 16ZM12.75 21H19.25C18.6096 23.0982 17.498 25.0223 16 26.625C14.5026 25.0218 13.391 23.098 12.75 21ZM12.2625 19C11.9192 17.0147 11.9192 14.9853 12.2625 13H19.7475C20.0908 14.9853 20.0908 17.0147 19.7475 19H12.2625ZM5.00001 16C4.99914 14.9855 5.13923 13.9759 5.41626 13H10.23C9.92334 14.9882 9.92334 17.0118 10.23 19H5.41626C5.13923 18.0241 4.99914 17.0145 5.00001 16ZM19.25 11H12.75C13.3904 8.90176 14.502 6.97773 16 5.375C17.4974 6.97815 18.609 8.90204 19.25 11ZM25.7913 11H21.3388C20.7776 8.94113 19.8318 7.00709 18.5513 5.3C20.0984 5.67166 21.5469 6.37347 22.7974 7.35732C24.0479 8.34117 25.0709 9.58379 25.7963 11H25.7913ZM13.4488 5.3C12.1683 7.00709 11.2224 8.94113 10.6613 11H6.20376C6.9291 9.58379 7.95209 8.34117 9.20261 7.35732C10.4531 6.37347 11.9016 5.67166 13.4488 5.3ZM6.20376 21H10.6613C11.2224 23.0589 12.1683 24.9929 13.4488 26.7C11.9016 26.3283 10.4531 25.6265 9.20261 24.6427C7.95209 23.6588 6.9291 22.4162 6.20376 21ZM18.5513 26.7C19.8318 24.9929 20.7776 23.0589 21.3388 21H25.7963C25.0709 22.4162 24.0479 23.6588 22.7974 24.6427C21.5469 25.6265 20.0984 26.3283 18.5513 26.7Z"
        fill="#217BCD"
      />
    </svg>
  </div>
</template>

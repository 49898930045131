<template>
  <div>
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.9375 9.75C8.9375 9.53451 9.0231 9.32785 9.17548 9.17548C9.32785 9.0231 9.53451 8.9375 9.75 8.9375H16.25C16.4655 8.9375 16.6722 9.0231 16.8245 9.17548C16.9769 9.32785 17.0625 9.53451 17.0625 9.75C17.0625 9.96549 16.9769 10.1722 16.8245 10.3245C16.6722 10.4769 16.4655 10.5625 16.25 10.5625H9.75C9.53451 10.5625 9.32785 10.4769 9.17548 10.3245C9.0231 10.1722 8.9375 9.96549 8.9375 9.75ZM9.75 13.8125H16.25C16.4655 13.8125 16.6722 13.7269 16.8245 13.5745C16.9769 13.4222 17.0625 13.2155 17.0625 13C17.0625 12.7845 16.9769 12.5779 16.8245 12.4255C16.6722 12.2731 16.4655 12.1875 16.25 12.1875H9.75C9.53451 12.1875 9.32785 12.2731 9.17548 12.4255C9.0231 12.5779 8.9375 12.7845 8.9375 13C8.9375 13.2155 9.0231 13.4222 9.17548 13.5745C9.32785 13.7269 9.53451 13.8125 9.75 13.8125ZM13 15.4375H9.75C9.53451 15.4375 9.32785 15.5231 9.17548 15.6755C9.0231 15.8278 8.9375 16.0345 8.9375 16.25C8.9375 16.4655 9.0231 16.6722 9.17548 16.8245C9.32785 16.9769 9.53451 17.0625 9.75 17.0625H13C13.2155 17.0625 13.4222 16.9769 13.5745 16.8245C13.7269 16.6722 13.8125 16.4655 13.8125 16.25C13.8125 16.0345 13.7269 15.8278 13.5745 15.6755C13.4222 15.5231 13.2155 15.4375 13 15.4375ZM22.75 4.875V15.9138C22.7507 16.1273 22.7089 16.3388 22.6272 16.536C22.5454 16.7332 22.4252 16.9122 22.2737 17.0625L17.0625 22.2737C16.9122 22.4252 16.7332 22.5454 16.536 22.6272C16.3388 22.7089 16.1273 22.7507 15.9138 22.75H4.875C4.44402 22.75 4.0307 22.5788 3.72595 22.274C3.4212 21.9693 3.25 21.556 3.25 21.125V4.875C3.25 4.44402 3.4212 4.0307 3.72595 3.72595C4.0307 3.4212 4.44402 3.25 4.875 3.25H21.125C21.556 3.25 21.9693 3.4212 22.274 3.72595C22.5788 4.0307 22.75 4.44402 22.75 4.875ZM4.875 21.125H15.4375V16.25C15.4375 16.0345 15.5231 15.8278 15.6755 15.6755C15.8278 15.5231 16.0345 15.4375 16.25 15.4375H21.125V4.875H4.875V21.125ZM17.0625 17.0625V19.9773L19.9763 17.0625H17.0625Z"
        fill="#217BCD"
      />
    </svg>
  </div>
</template>

<template>
  <div>
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.125 3.25H4.875C4.44402 3.25 4.0307 3.4212 3.72595 3.72595C3.4212 4.0307 3.25 4.44402 3.25 4.875V19.5C3.25 19.931 3.4212 20.3443 3.72595 20.649C4.0307 20.9538 4.44402 21.125 4.875 21.125H6.5V24.375C6.49994 24.5294 6.5439 24.6807 6.62673 24.811C6.70955 24.9414 6.82781 25.0454 6.96765 25.111C7.10749 25.1765 7.26311 25.2009 7.41628 25.1811C7.56946 25.1614 7.71383 25.0984 7.8325 24.9996L12.482 21.125H16.768C17.1484 21.1251 17.5168 20.9918 17.809 20.7482L22.165 17.1173C22.3484 16.9652 22.496 16.7744 22.5971 16.5587C22.6982 16.3429 22.7504 16.1074 22.75 15.8691V4.875C22.75 4.44402 22.5788 4.0307 22.274 3.72595C21.9693 3.4212 21.556 3.25 21.125 3.25ZM21.125 15.8691L16.768 19.5H12.1875C11.9975 19.4999 11.8135 19.5664 11.6675 19.6879L8.125 22.6403V20.3125C8.125 20.097 8.0394 19.8903 7.88702 19.738C7.73465 19.5856 7.52799 19.5 7.3125 19.5H4.875V4.875H21.125V15.8691ZM16.25 13.8125V8.9375C16.25 8.72201 16.3356 8.51535 16.488 8.36298C16.6403 8.2106 16.847 8.125 17.0625 8.125C17.278 8.125 17.4847 8.2106 17.637 8.36298C17.7894 8.51535 17.875 8.72201 17.875 8.9375V13.8125C17.875 14.028 17.7894 14.2347 17.637 14.387C17.4847 14.5394 17.278 14.625 17.0625 14.625C16.847 14.625 16.6403 14.5394 16.488 14.387C16.3356 14.2347 16.25 14.028 16.25 13.8125ZM11.375 13.8125V8.9375C11.375 8.72201 11.4606 8.51535 11.613 8.36298C11.7653 8.2106 11.972 8.125 12.1875 8.125C12.403 8.125 12.6097 8.2106 12.762 8.36298C12.9144 8.51535 13 8.72201 13 8.9375V13.8125C13 14.028 12.9144 14.2347 12.762 14.387C12.6097 14.5394 12.403 14.625 12.1875 14.625C11.972 14.625 11.7653 14.5394 11.613 14.387C11.4606 14.2347 11.375 14.028 11.375 13.8125Z"
        fill="#217BCD"
      />
    </svg>
  </div>
</template>
